function Header() {
    const logoHeight = 30
    const logoWidth = 30
    return (
        <div className='col-start-6 col-span-2 justify-self-end self-start pr-[50px] flex flex-row gap-3'>
            <div className='cursor-pointer' onClick={() => {
                window.open("", "_blank")
            }}>
                <img src="/opensealogo.png" width={logoWidth} height={logoHeight} />
            </div>
            <div className='cursor-pointer' onClick={() => {
                window.open("", "_blank")
            }}>
                <img src="/etherscanlogo.png" width={logoWidth} height={logoHeight} />
            </div>
            <div className='cursor-pointer' onClick={() => {
                window.open("https://twitter.com/timithnft", "_blank")
            }}>
                <img src="/twitterlogo.png" width={logoWidth} height={logoHeight} />
            </div>
            <div className='cursor-pointer' onClick={() => {
                window.open("https://discord.gg/timith", "_blank")
            }}>
                <img src="/discordlogo.png" width={logoWidth * 1.3} height={logoHeight} />
            </div>
        </div>
    )
}

export default Header;
