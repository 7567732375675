import React from 'react';
import classname from 'classnames';

function WalletChecker({ loading, onClick }) {
    const [input, setInput] = React.useState('')
    return (
        <div className='col-start-1 col-span-3 row-start-3 row-span-3 p-6 flex align-center justify-center'>
            <div className='flex flex-col w-full items-center gap-1'>
                <div className='text-2xl font-bold'>Enter your Wallet</div>
                <input type="text" placeholder="0x312...3231" value={input} onChange={(e) => setInput(e.target.value)} className="input w-full max-w-xs" />
                <button
                    onClick={() => onClick(input)}
                    className={classname("btn w-full max-w-xs bg-[#2aa9e1] text-black hover:text-white hover:bg-[#2aa9e1]/70", loading && 'loading')}
                    disabled={loading}>Check</button>
            </div>
        </div>

    )
}

export default WalletChecker;
