import Header from "./components/Header";
import React from 'react';
import WalletChecker from "./components/WalletChecker";

function App() {
    const [loading, setLoading] = React.useState(false)
    const [result, setResult] = React.useState({ error: false, success: false, message: '' })

    const requestPhase = async (address) => {
        const url = `https://us-central1-nft-timith.cloudfunctions.net/app/timith/checkWallet/${address}`
        const response = await fetch(url)
        return response.json()
    }
    const onClickHandler = async (input) => {


        try {
            if (!input) {
                throw new Error("Empty Wallet")
            }
            setLoading(true)
            const message = await requestPhase(input)
            if (message.success) {

                setResult({
                    error: false,
                    success: message.success,
                    message: message.message,
                })
            } else {
                throw new Error(message.message)
            }
        } catch (err) {
            setResult({
                error: true,
                success: false,
                message: err.message,
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="bg-cover bg-center justify-center min-h-screen py-2 grid grid-cols-6 grid-rows-6"
            style={{ backgroundImage: "url(./timithMintBG.png)" }}>
            <Header />
            <div className="col-span-3 pl-4">
                {result.success && (
                    <div className="alert alert-success shadow-lg">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                            <span>{result.message}</span>
                        </div>
                    </div>
                )}
                {result.error && (
                    <div className="alert alert-error shadow-lg">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                            <span>{result.message}</span>
                        </div>
                    </div>
                )}
            </div>
            <WalletChecker loading={loading} onClick={onClickHandler} />
        </div>
    );
}

export default App;
